<template>
  <div>
    <v-card>
      <SlideToggle :open="open">
        <v-card-title v-if="toggleFilterState">
          <v-row>
            <v-col cols="12" sm="2" md="1">Filtros</v-col>

            <v-col cols="12" sm="10" md="4" class="hide-detail-date-picker">
              <vc-date-picker
                @input="onDateRangeChangeReporte"
                v-model="rangeFilter"
                :locale="{
                  id: 'es',
                  firstDayOfWeek: 2,
                  masks: { weekdays: 'WWW' },
                }"
                is-range
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    label="Desde - Hasta"
                    append-icon="mdi-calendar"
                    :value="inputValue.start + ' - ' + inputValue.end"
                    v-on="inputEvents.start"
                    readonly
                    outlined
                    dense
                    :disabled="!formFilter.enabledRanfeFecha"
                  >
                    <template v-slot:prepend>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-checkbox
                              v-model="formFilter.enabledRanfeFecha"
                              hide-details
                              class="shrink m-0 mb-4"
                            ></v-checkbox>
                          </span>
                        </template>
                        <span>Desactiva el filtro por fecha</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </template>
              </vc-date-picker>
            </v-col>
            <v-col cols="12" sm="9" md="5">
              <v-row no-gutters>
                <v-col cols="4" class="radios-right-0">
                  <v-select
                    v-model="formFilter.namefield"
                    :items="headersMap"
                    item-text="text"
                    menu-props="auto"
                    label="Seleccionar columna"
                    hide-details
                    append-icon="mdi-filter"
                    single-line
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="8" class="radios-left-0">
                  <v-text-field
                    v-model="formFilter.value"
                    label="Ingrese el dato a buscar"
                    outlined
                    clearable
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="3" md="2">
              <v-btn color="primary" block @click="filtrarTabla">
                <v-icon> mdi-magnify </v-icon> Buscar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
      </SlideToggle>
      <v-divider v-if="!loadTable"></v-divider>
      <v-card-title v-if="tDataBody.length > 0">
        <v-row>
          <v-col cols="6" md="3" sm="6" lg="2">
            <v-select
              v-model="selectedHeaders"
              :items="headers"
              label=""
              multiple
              outlined
              return-object
              solo
              dense
              :elevation="false"
              class="select-box-shadow-none"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 1" style="background: transparent">
                  <v-icon left> mdi-view-column </v-icon>
                  Columnas
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="6" md="5" sm="6" lg="7">
            <v-btn-toggle mandatory>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    class="pt-5 pb-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleFilter"
                  >
                    <v-icon :left="!$vuetify.breakpoint.xsOnly">
                      {{ open ? "mdi-eye-off" : "mdi-eye " }}</v-icon
                    >
                    <span class="hidden-sm-and-down">Filtros</span>
                  </v-btn>
                </template>
                <span>{{ open ? "Ocultar" : "Mostrar" }} Filtro</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    class="pt-5 pb-5"
                    @click="ActionTableRefresh"
                    :loading="refreshButtonState"
                    :disabled="refreshButtonState"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :left="!$vuetify.breakpoint.xsOnly">
                      mdi-refresh
                    </v-icon>
                    <span class="hidden-sm-and-down">Refrescar</span>
                  </v-btn>
                </template>
                <span>Refrescar tabla</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    value="right"
                    small
                    class="pt-5 pb-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="ActionTableExportToExcel"
                    ><v-icon :left="!$vuetify.breakpoint.xsOnly">
                      mdi-file-export
                    </v-icon>
                    <span class="hidden-sm-and-down">Excel</span>
                  </v-btn>
                </template>
                <span>Exportar a Excel</span>
              </v-tooltip>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="4" sm="12" lg="3">
            <v-text-field
              dense
              clearable
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <br v-if="loadTable" />
      <v-data-table
        v-model="selectedRows"
        :headers="showHeaders"
        :items="tDataBody"
        :search="search"
        :loading="loadTable"
        fixed-header
        height="60vh"
        @contextmenu:row="rightClickHandler"
        single-select
        item-key="id"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
        }"
      >
        <template v-slot:item.visitado="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                :color="item.visitado > 0 ? 'green' : 'gray'"
                v-show="item"
                v-bind="attrs"
                v-on="on"
                text-color="white"
                small
              >
                <v-icon v-if="item.visitado > 0">mdi-eye-check-outline</v-icon>
                <v-icon v-else>mdi-eye-settings-outline</v-icon>
              </v-chip>
            </template>
            <span> Se visitó el enlace {{ item.visitado }} veces</span>
          </v-tooltip>
        </template>
        <template v-slot:item.incidencias="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                :color="item.incidencias > 0 ? 'red' : 'success'"
                v-show="item"
                v-bind="attrs"
                v-on="on"
                text-color="white"
                small
              >
                <v-avatar left>
                  <v-icon v-if="item.incidencias > 0"
                    >mdi-emoticon-sad-outline
                  </v-icon>
                  <v-icon v-else>mdi-emoticon-outline</v-icon>
                </v-avatar>
                {{ item.incidencias }}
              </v-chip>
            </template>
            <span> Ocurrieron {{ item.incidencias }} incidencias</span>
          </v-tooltip>
        </template>
        <template v-slot:item.estado="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                :color="getColorState(item.estado)"
                v-bind="attrs"
                v-on="on"
                text-color="white"
                small
              >
                <v-avatar left v-if="item.estado == 'C'">
                  <v-icon small>mdi-checkbox-marked-circle</v-icon>
                </v-avatar>
                <v-avatar left v-if="item.estado == 'V'">
                  <v-icon small>mdi-face-recognition</v-icon>
                </v-avatar>
                {{ item.estado | textEstado }}
              </v-chip>
            </template>
            <span> {{ item.estado | textEstadoDesc }}</span>
          </v-tooltip></template
        >
        <template v-slot:item.estado_final="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                :color="getColorStateFinFirma(item.estado_final)"
                v-bind="attrs"
                v-on="on"
                text-color="white"
                small
              >
                <v-avatar left v-if="item.estado_final == 'T'">
                  <v-progress-circular
                    indeterminate
                    :size="15"
                    width="3"
                    :value="50"
                  ></v-progress-circular>
                </v-avatar>
                <v-avatar left v-if="item.estado_final == 'C'">
                  <v-icon small>mdi-checkbox-marked-circle</v-icon>
                </v-avatar>
                <v-avatar left v-if="item.estado_final == 'P'">
                  <v-icon small>mdi-timer-sand</v-icon>
                </v-avatar>
                <v-avatar left v-if="item.estado_final == 'F'">
                  <v-icon small>mdi-alert-circle</v-icon>
                </v-avatar>

                {{ item.estado_final | textEstadoFinFirma }}
              </v-chip>
            </template>
            <span> {{ item.estado_final | textEstadoFinFirmaDesc }} </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-card class="mx-auto" max-width="600" tile>
        <v-list dense>
          <v-subheader>Menu</v-subheader>
          <v-list-item-group>
            <template v-for="(item, i) in items">
              <v-list-item
                v-if="!item.divider"
                :key="i"
                @click="menuEventClick(item.title, item.action)"
                link
              >
                <v-list-item-icon class="mr-4">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-else :key="i"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
    <v-dialog v-model="dialogLoader" hide-overlay persistent width="400">
      <v-card color="primary" dark>
        <v-card-text>
          Programando reintento, espere un momento porfavor.
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <vmodal
      :title="modalSetting.title"
      :openmodal="modalSetting.show"
      :json="modalSetting.data"
      v-on:closeEvent="changeOpenModal"
      v-if="modalSetting.show"
    ></vmodal>
    <vdialogdetalledevice
      :title="modalSetting.title"
      :openmodal="modalSetting.show2"
      :json="modalSetting.data"
      v-on:closeEvent="changeOpenModal"
      v-if="modalSetting.show2"
    ></vdialogdetalledevice>
    <vdialogdetalle
      :title="modalSetting.title"
      :openmodal="modalSetting.show3"
      :json="modalSetting.data"
      v-on:closeEvent="changeOpenModal"
      v-if="modalSetting.show3"
    ></vdialogdetalle>

    <vdialogdetallecliente
      :title="modalSetting.title"
      :openmodal="modalSetting.show4"
      :json="modalSetting.data"
      v-on:closeEvent="changeOpenModal"
      v-if="modalSetting.show4"
    ></vdialogdetallecliente>
    <vdialoghistorialfinfirma
      :title="modalSetting.title"
      :openmodal="modalSetting.show5"
      :json="modalSetting.data"
      v-on:closeEvent="changeOpenModal"
      v-if="modalSetting.show5"
    ></vdialoghistorialfinfirma>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import vmodal from "./vmodal.vue";
import vdialogdetalledevice from "./vdialog-detalle-device.vue";
import vdialogdetallecliente from "./vdialog-detalle-cliente.vue";
import vdialoghistorialfinfirma from "./vdialog-historial-finfirma.vue";
import vdialogdetalle from "./vdialog-detalle.vue";
import { VueSlideToggle } from "vue-slide-toggle";

import menu from "./menu.js";
export default {
  components: {
    vmodal,
    vdialogdetalledevice,
    vdialogdetalle,
    vdialogdetallecliente,
    vdialoghistorialfinfirma,
    SlideToggle: VueSlideToggle,
  },
  data() {
    return {
      ...menu.data,
      selectedRows: [],
      formFilter: {
        namefield: "",
        value: "",
        enabledRanfeFecha: true,
      },
      rangeFilter: {
        start: new Date(),
        end: new Date(),
      },
      open: true,
      selectedHeaders: [],
      overlay: false,
      memoryDataLogBiometry: [],
      memoryDataSendFinFirma: [],
      memoryResponseFinFirma: [],
      memoryResponseHistorialFinFirma: [],
      modalSetting: {
        title: "",
        show: false,
        show2: false,
        show3: false,
        show4: false,
        show5: false,
        data: [],
      },
      fromDateMenu: false,
      fromDateVal: null,
      selectedDate: null,
      refreshButtonState: false,
      loadTable: false,
      search: "",
      headers: [],
      headersMap: [
        {
          text: "ID",
          align: "start",
          value: "id",
          width: "70",
        },
        { text: "ID Transaccion", value: "idtransaccion", width: "180" },
        { text: "Nombre", value: "nombre", width: "100" },
        { text: "Otros Nombres", value: "nombre_otros", width: "150" },
        { text: "Paterno", value: "apellido_pat", width: "100" },
        { text: "Materno", value: "apellido_mat", width: "100" },
        { text: "DNI", value: "numero_documento", width: "100" },
        { text: "Fecha", value: "datireg", width: "200" },
        { text: "Visto", value: "visitado", width: "150" },
        { text: "Incidencias", value: "incidencias", width: "150" },
        { text: "Estado Cliente", value: "estado", width: "130" },
        { text: "Estado Fin Firma", value: "estado_final", width: "150" },
      ],
      tDataHeader: [],
      tDataBody: [],
      showMenu: false,
      x: 0,
      y: 0,
      selectedItem: 0,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      loadAjax: false,
      toggleFilterState: true,
      selectIdTransaccion: null,
      selectIdTransaccionDataRow: {},
    };
  },
  async created() {},
  methods: {
    ...menu.methods,
    rightClickHandler(e, row) {
      e.preventDefault();
      let item = row.item;
      row.select(true);
      this.selectIdTransaccion = item.id;
      this.selectTransaccionDataRow = item;
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    async menuEventClick(t, e) {
      this.modalSetting.title = t;
      let id = this.selectIdTransaccion;
      this.overlay = true;
      if (e == "copyTokenPage" || e == "copyTokenPage2") {
        let tokenpage = this.selectTransaccionDataRow.tokenpage;
        if (e == "copyTokenPage2")
          tokenpage = `${window.location.protocol}//${window.location.host}${
            process.env.NODE_ENV === "production" ? "/app/" : "/"
          }verify/${tokenpage}`;
        this.copy(tokenpage);
        this.toastAlert("success", "Token copiado!", 2000, "bottom-end");
      } else if (
        e == "logBiometryc" ||
        e == "logDataFinFirma" ||
        e == "logResponseFinFirma"
      ) {
        let data = null;
        let datatemporal = null;
        if (e == "logBiometryc") {
          data = await this.consultar(id, this.$apis.consola.logBiometric);
          datatemporal = this.memoryDataLogBiometry;
          data = data.data;
        } else if (e == "logDataFinFirma") {
          let estado_final = this.selectTransaccionDataRow.estado_final;
          if (estado_final == "E")
            return this.stopMenuSearch("Registro sin firmar");
          data = await this.consultar(id, this.$apis.consola.showDataFinFirma);
          datatemporal = this.memoryDataSendFinFirma;
          if (data.error)
            return this.stopMenuSearch(
              "No se han generado los reportes o contratos"
            );
          data = data.data;
        } else if (e == "logResponseFinFirma") {
          data = this.selectTransaccionDataRow.historico;
          datatemporal = this.memoryResponseFinFirma;
          if (data === null)
            return this.stopMenuSearch("Sin datos por mostrar");

          if (this.isObject(data)) {
            data = JSON.parse(data);
          }
        }
        let index = await datatemporal.findIndex((a) => a.tab == id);
        if (index != -1) await datatemporal.splice(index, 1);
        let structuraData = { tab: id, content: data };
        datatemporal = [structuraData, ...datatemporal];
        if (e == "logBiometryc") this.memoryDataLogBiometry = datatemporal;
        else if (e == "logDataFinFirma")
          this.memoryDataSendFinFirma = datatemporal;
        else if (e == "logResponseFinFirma")
          this.memoryResponseFinFirma = datatemporal;

        this.modalSetting.data = datatemporal;
        this.modalSetting.show = true;
      } else if (e == "detalleDispositivo") {
        let rs = await this.consultar(
          id,
          this.$apis.consola.getDetalleDispositivo
        );
        if (rs.data.length == 0)
          return this.stopMenuSearch("No hay detalles del dispositivo");
        this.modalSetting.data = { id: id, data: rs.data };
        this.modalSetting.show2 = true;
      } else if (e == "incidencias") {
        let incidencias = this.selectTransaccionDataRow.incidencias;
        if (incidencias == 0) return this.stopMenuSearch("No hay incidencias");

        let rs = await this.consultar(id, this.$apis.consola.getIncidencias);
        if (rs.data.length == 0)
          return this.stopMenuSearch("No hay incidencias");
        this.modalSetting.data = { id: id, data: rs.data };
        this.modalSetting.show3 = true;
      } else if (e == "reintentar-finfirma") {
        let estado_final = this.selectTransaccionDataRow.estado_final;
        if (estado_final == "C")
          return this.stopMenuSearch(
            `La transacci&oacute;n ${id} ya se encuentra completada`
          );
        let data = await this.consultar(
          id,
          this.$apis.consola.reintentoFinFirma
        );

        if (data.error) this.stopMenuSearch(data.message);
        else
          this.toastAlert(
            "success",
            `Reintento programado exitosamente a la transacci&oacute;n ${id}`,
            3000,
            "bottom-end"
          );
      } else if (e == "detalle-cliente") {
        this.modalSetting.data = {
          id: id,
          data: this.selectTransaccionDataRow,
        };
        this.modalSetting.show4 = true;
      } else if (e == "historial-finfirma") {
        let data = null;

        data = await this.consultar(
          id,
          this.$apis.consola.getHistorialFinFirma
        );
        data = data.data;
        if (data.length == 0) return this.stopMenuSearch("No hay reintentos registrados");
        this.modalSetting.data = { id: id, data: data };
        this.modalSetting.show5 = true;
      }
      this.overlay = false;
    },
    async ActionTableExportToExcel() {
      let $self = this;
      var hoy = new Date();
      var fecha =
        hoy.getDate() + "-" + (hoy.getMonth() + 1) + "-" + hoy.getFullYear();
      var hora =
        "h" + hoy.getHours() + "m" + hoy.getMinutes() + "s" + hoy.getSeconds();
      var fechaYHora = fecha + " " + hora;

      if (this.headersMap.length < 0)
        return this.toastAlert(
          "info",
          "No hay registros para exportar",
          2000,
          "bottom-end"
        );
      await $self.$swal
        .fire({
          title: "Exportar en excel",
          html: "Confirme para iniciar la descarga",
          icon: "info",
          background: "#fff",
          backdrop: `rgb(0 0 0 / 24%)`,
          showCancelButton: true,
          confirmButtonText: "Sí, Descargar!",
          cancelButtonText: `Cerrar`,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            await new Promise((resolve) => {
              setTimeout(() => {
                const xs = {
                  data: this.tDataBody,
                  columns: this.headersMap,
                  fileName: "transacciones_" + fechaYHora,
                  fileType: "xlsx",
                  sheetName: "sheetname",
                };

                $self.exportExcel(xs);
                resolve(true);
              }, 1000);
            });
          },
          allowOutsideClick: () => !$self.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.toastAlert("success", "Descargado", 2000, "bottom-end");
          }
        });
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    changeOpenModal() {
      this.modalSetting.title = "";
      this.modalSetting.data = [];
      this.modalSetting.show =
        this.modalSetting.show2 =
        this.modalSetting.show3 =
        this.modalSetting.show4 =
        this.modalSetting.show5 =
          false;
    },
    async consultarTest(id, url) {
      const response = await axios
        .get(url + "/" + id)
        .catch((error) => error.response);
      if (response.status != 200) {
        if (response.status == 500)
          return this.stopSecuence("Error en el servidor");
        else if (response.status == 404)
          return this.stopSecuence(
            !response.data.message
              ? "No hay conexión con el servicio"
              : response.data.message
          );
        else return this.stopSecuence(response.data.message);
      }

      return response.data;
    },
    async consultar(id, url) {
      const article = { id: id };
      const response = await axios
        .post(url, article)
        .catch((error) => error.response);
      if (response.status != 200) {
        if (response.status == 500)
          return this.stopSecuence("Error en el servidor");
        else if (response.status == 404)
          return this.stopSecuence(
            !response.data.message
              ? "No hay conexión con el servicio"
              : response.data.message
          );
        else return this.stopSecuence(response.data.message);
      }

      return response.data;
    },
    async filtrarTabla() {
      this.toggleFilter();
      this.loadTable = true;

      this.refreshButtonState = this.loadTable = true;
      this.tDataHeader = this.headers = this.tDataBody = [];
      let getDataTable = await this.getDataTable();
      if (!getDataTable) return false;
      this.tDataBody = getDataTable;

      if (this.tDataBody.length > 0) {
        // this.tDataHeader = this.headersMap;
        this.headers = Object.values(this.headersMap);
        this.selectedHeaders = this.headers;
        this.loadTable = this.refreshButtonState = false;
      } else {
        this.headers = [];
        this.loadTable = this.refreshButtonState = false;
        this.toggleFilterState = true;
        this.toggleFilter();
      }
    },
    toggleFilter() {
      this.open = !this.open;
      // this.toggleFilterState = !this.toggleFilterState;
    },
    addDate() {
      this.dates.push({
        date: new Date(),
      });
      this.$nextTick(() => {
        const btn = this.$refs.button[this.$refs.button.length - 1];
        btn.click();
      });
    },
    removeDate(date, hide) {
      this.dates = this.dates.filter((d) => d !== date);
      hide();
    },
    dateSelected(e, date, toggle) {
      this.selected = date;
      toggle({ ref: e.target });
    },
    onDateRangeChangeReporte() {
      this.loadAjax = true;
    },
    async ActionTableRefresh() {
      this.refreshButtonState = this.loadTable = true;
      this.headers = this.tDataBody = [];
      let getDataTable = await this.getDataTable();
      if (!getDataTable) return false;
      this.tDataBody = getDataTable;
      this.headers = this.headersMap;
      this.refreshButtonState = this.loadTable = false;
    },
    async getDataTable() {
      let $self = this;
      const article = {
        start: this.moment(this.rangeFilter.start).format("YYYY-MM-DD"),
        end: this.moment(this.rangeFilter.end).format("YYYY-MM-DD"),
        columname: this.formFilter.namefield,
        value: this.formFilter.value,
        disabledrange: this.formFilter.enabledRanfeFecha,
      };
      const response = await axios
        .post($self.$apis.listado, article)
        .catch((error) => error.response);
      if (response.status != 200) {
        if (response.status == 500)
          return $self.stopSecuence("Error en el servidor");
        else if (response.status == 404)
          return $self.stopSecuence(
            !response.data.message
              ? "No hay conexión con el servicio"
              : response.data.message
          );
        else return $self.stopSecuence(response.data.message);
      }
      if (response.data.error) return $self.stopSecuence(response.data.message);
      return response.data.data;
    },
    stopSecuence(v) {
      this.toastAlert("info", v, 2000, "bottom-end");
      this.headers = [];
      this.loadTable = this.refreshButtonState = false;
      this.toggleFilterState = true;
      this.toggleFilter();

      return false;
    },

    stopMenuSearch(msj) {
      this.overlay = false;
      this.toastAlert("info", msj, 3000, "bottom-end");
    },
  },
  filters: {
    ...menu.filters,
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    fromDateDisp() {
      return this.fromDateVal;
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },
  watch: {
    ...menu.watch,
  },
};
</script>

<style >
.select-box-shadow-none .v-input__slot {
  box-shadow: none !important;
}
.v-text-field__details {
  display: none;
}

.list-active-off .v-list-item--active .v-list-item__icon {
  color: rgb(117 117 117);
}
.list-active-off .v-list-item--active .v-list-item__content {
  color: rgb(60 60 60);
}
</style>