<template>
    <a 
     @click="eventoClick(todo.id)"
      class="list-group-item text-color-666 border-0 hover:bg-gray-100 hover:text-gray-800 
                transition
                duration-100
                cursor-pointer " 
                :class="{active: todo.active}"
      ><i class="fa fa-file-pdf txt-salmon"></i> {{todo.title}}</a
    >

    
  
</template>

<script>
export default {
  name: "TodoItem",
  props: ["todo"],
  data(){
    return {
      a_disabled: false,
    }
  },
  methods: {
    eventoClick(v){
         this.$emit('item-documento', v);
    }
  }
};
</script>

<style scoped>

</style>