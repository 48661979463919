
<template>
  <div>
    <Modal v-show="isModalVisible" @close="closeModal">
      <template v-slot:loading>
        <div v-if="!startSendOtp" class="text-center">
          <LoadSpin2 />
        </div>
      </template>
      <template v-slot:header v-if="startSendOtp">
        <h2 class="swal2-title text-center" style="display: block">
          Firmar con código
        </h2>
      </template>

      <template v-slot:body v-if="startSendOtp">
        <div
          class="swal2-html-container"
          style="display: block; margin: 0; margin-bottom: 2rem"
        >
          <div>
            Introduzca el código que ha recibido en su correo electrónica
          </div>
        </div>

        <div style="display: flex; flex-direction: row">
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            separator=" "
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-change="handleOnChange"
            @on-complete="handleOnComplete"
          />


        </div>
      </template>

      <template v-slot:footer v-if="startSendOtp">
        <button
          type="button"
          class="
            text-white
            btn-salmon
            hover:btn-salmon
            focus:ring-4 focus:ring-blue-300
            font-medium
            rounded-lg
            text-sm
            px-5
            py-2.5
            text-center
            mr-2
            mb-2
            dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
            w-full
          "
          :disabled="buttonDisabled"
          :class="{ 'cursor-not-allowed': buttonDisabled }"
          @click="confirmar()"
        >
          Firmar
        </button>
        <button
          @click="closeModal()"
          type="button"
          class="
            text-gray-900
            bg-white
            border border-gray-300
            hover:bg-gray-100
            focus:ring-4 focus:ring-blue-300
            font-medium
            rounded-lg
            text-sm
            px-5
            py-2.5
            text-center
            mr-2
            mb-2
            dark:bg-gray-600
            dark:text-white
            dark:border-gray-600
            dark:hover:bg-gray-700
            dark:hover:border-gray-700
            dark:focus:ring-gray-800
            w-full
          "
        >
          Cancelar
        </button>
        <div class="text-center">
          <a
            @click="reenviarOtp()"
            style="color: #4c4cf9; text-decoration: underline"
            className="underline cursor-pointer text-blue-500 handler hover:text-blue-800 visited:text-purple-600"
            :disabled="loadSendOtp"
            :class="{ 'cursor-not-allowed': loadSendOtp }"
            >Reenviar OTP
            <font-awesome-icon
              :icon="['fas', 'spinner']"
              spin
              v-if="loadSendOtp"
            />
            <i v-else class="fa fa-envelope"></i>
          </a>
        </div>
      </template>
    </Modal>
    <div class="grid md:grid-cols-3 lg:grid-cols-4">
      <div class="">
        <div
          class="flex items-center pb-3"
          style="
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
          "
        >
          <img
            :src="logo"
            alt="Logo"
            class="h-6 ml-4 mt-3"
            draggable="false"
            style="margin-bottom: 2px"
          />
          <BtnDocuments
            style="display: none"
            v-if="documentos"
            v-on:show-documentos="showDocumentos"
            html='<i class="fa fa-bars"></i>'
          />
          <v-app class="btn-custom-right">
            <v-bottom-sheet v-model="sheet">
              <template v-slot:activator="{ on, attrs }" >
                <v-btn color=" btn-salmon hover:btn-salmon  visible  md:invisible" dark v-bind="attrs" v-on="on" >
                  <i class="fa fa-bars"></i>
                </v-btn>
              </template>
              <v-list>
                <v-subheader>Documentos</v-subheader>
                <v-list-item
                  v-for="tile in documentos"
                  :key="tile.id"
                  @click="sheet = false"
                >
                  <v-list-item-avatar>
                    <v-avatar size="32px" tile>
                     <i class="fas fa-file-pdf text-8xl txt-salmon"></i>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-title @click="clickDocumento(tile.id)">{{ tile.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-bottom-sheet>
          </v-app>
        </div>
        <div
          class="
            contenedor-info
            hidden-responsive
            p-4
            rounded-lg
            hidden
            md:block
          "
        >
          <div class="alert alert-info">
            <p
              v-html="info"
              :class="!documentos ? 'skeleton-text skeleton-effect-wave' : ''"
            ></p>
          </div>
        </div>

        <div v-if="!documentos" class="p-4 hidden md:block">
          <PuSkeleton
            v-for="index in 3"
            :key="index"
            height="40px"
            class="skeleton-effect-wave"
          />
        </div>
        <Documento
          v-else
          v-bind:todoslist="documentos"
          v-on:emit-documento="clickDocumento"
          class="hidden md:block"
        />

        <div class="p-4 hidden md:block">
          <btnFirma
            v-on:firmar-documento="iniciarFirma()"
            clases="w-full"
            html="<i class='fa fa-pencil'></i> Firmar"
          />
        </div>
      </div>
      <div class="md:col-span-2 lg:col-span-3" id="visorPrincipal">
        <div v-show="!pdfdoc" class="loaderIframe">
          <div class="container">
            <div class="row">
              <div class="col mb-3">
                <i class="fas fa-file-pdf text-8xl txt-salmon"></i>
                <LoadSpin3 class="txt-salmon" />
              </div>
              <div class="w-100"></div>
            </div>
          </div>
        </div>
        <vue-pdf-app
          v-show="pdfdoc"
          theme="light"
          :config="config"
          :pdf="pdfdoc"
        ></vue-pdf-app>
      </div>
      <div id="carbonads" class="visible md:invisible">
        <BtnFirma
          v-if="documentos"
          v-on:firmar-documento="iniciarFirma()"
          clases="shadow"
          html="<i class='fa fa-pencil'></i> Firmar"
        />
      </div>
    </div>
  </div>
</template>
<script>

import { Documento, BtnFirma, BtnDocuments, LoadSpin2, LoadSpin3, Modal } from "@/core/components/firmador/";
import axios from "axios";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
export default {
  name: "vista-signer",
  props: ["swapComponent"],
  components: {
    Documento,
    VuePdfApp,
    BtnFirma,
    BtnDocuments,
    Modal,
    LoadSpin2,
    LoadSpin3,
  },
  data() {
    return {
      sheet: false,
      tiles: [
        { img: "keep.png", title: "Keep" },
        { img: "inbox.png", title: "Inbox" },
        { img: "hangouts.png", title: "Hangouts" },
        { img: "messenger.png", title: "Messenger" },
        { img: "google.png", title: "Google+" },
      ],
      isModalVisible: false,
      dataModal: "",
      pdfdoc: null,
      logo: require("../../../assets/img/logo.png"),
      logoPrealoader: require("../../../assets/img/logo-blanco-preloader.svg"),
      currentComponent: "Pasosdelproceso",
      info: `Estimado usuario.<br />
            Su proceso de validación biométrica ha finalizado de forma exitosa.
            A continuación debe realizar el proceso de firma de los contratos
            resultantes de su proceso de vinculación.`,
      documentos: null,
      config: {
        secondaryToolbar: false,
        toolbar: {
          toolbarViewerLeft: false,
          toolbarViewerRight: false,
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
          },
        },
        errorWrapper: true,
        scrollVertical: "visible",
        scrollWrapped: "true",
      },
      startSendOtp: false,
      loadSendOtp: false,
      codeOtp: null,
      buttonDisabled: true,
    };
  },
  methods: {
    showDocumentos: function () {
      const swal = this.$swal;
      swal.fire({
        title: "Documentos",
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Cerrar",
        confirmButtonAriaLabel: "Cerrar",
        html: `<div class="h-52 text-left">
                  <a href="javascript:;" id="btnA" data-document="../../documentos/userid/flujo/91234567_Contrato.pdf" class="list-group-item"><i class="fa fa-file-pdf"></i> Contrato.pdf </a>
                  <a href="javascript:;" id="btnB" data-document="../../documentos/userid/flujo/91234567_DeclaracionMercados.pdf" class="list-group-item"><i class="fa fa-file-pdf"></i> DeclaracionMercados.pdf </a>
                  <a href="javascript:;" id="btnC" data-document="../../documentos/userid/flujo/91234567_DeclaracionResidencia.pdf" class="list-group-item"><i class="fa fa-file-pdf"></i> DeclaracionResidencia.pdf</a>
                  <a href="javascript:;" data-document="../../documentos/userid/flujo/91234567_Perfil.pdf" class="list-group-item"><i class="fa fa-file-pdf"></i> Perfil.pdf</a>
              </div>`,
        didOpen: () => {
          let btnA = document.querySelector("#btnA");
          btnA.addEventListener("click", function (event) {
            event.preventDefault();
            swal.close();
          });
        },
      });
    },
    async iniciarFirma() {
      this.showModal();
      if (!this.startSendOtp) {
        await this.sendOtp();
        this.startSendOtp = !this.startSendOtp;
      }

      // this.errorAlert("Alerta",`<span class="float-left">Estimado(a) Usuario:</span> No se lograron firmar los documentos.`,false);
      // this.errorAlert("Alerta",`<span class="float-left">Estimado(a) Usuario:</span> No ha enviado los datos requeridos.`,false);
      // this.errorAlert("Alerta",`<span class="float-left">Estimado(a) Usuario:</span> No se encontr\u00f3 conexi\u00f3n con el servidor.`,false);
    },
    async reenviarOtp() {
      if (!this.loadSendOtp) {
        this.loadSendOtp = !this.loadSendOtp;
        await this.sendOtp();
        this.loadSendOtp = !this.loadSendOtp;
      }
    },
    sendOtp() {
      let $self = this;
      return new Promise(function (resolve, reject) {
        let idparam = $self.$route.params.token;
        const article = { token: idparam };
        axios
          .post($self.$apis._SendOtp, article)
          .then((r) => {
            let rs = r.data;
            $self.toastAlert("success", `C&oacute;digo OTP enviado.`, 3000);
            resolve(rs);
          })
          .catch(() => reject("error"));
      });
    },
    handleOnComplete(v) {
      this.codeOtp = v;
      this.buttonDisabled = false;
    },
    handleOnChange() {
      this.buttonDisabled = true;
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    confirmar() {
      let $self = this;
      this.closeModal();
      const swal = this.$swal;
      let idparam = $self.$route.params.token;
      const article = { token: idparam, otp: this.codeOtp };
    this.sendAlert(
        "Firma",
        "Iniciando firma de contratos"
      );
      axios
        .post($self.$apis._SignContract, article)
        .then((r) => {
          let rs = r.data;
          rs;
          swal.fire({
            icon: "success",
            title: "El proceso ha finalizado correctamente",
            html: `<div class="text-left"><span class="text-left">Estimado(a) Usuario:</span>  <br><br> Su solicitud de vinculaci&oacute;n ha sido enviada exitosamente al equipo de Credicorp Capital, quien revisar&aacute; la informaci&oacute;n y documentaci&oacute;n ingresada. Una vez finalizada la validaci&oacute;n  recibir&aacute; un correo electr&oacute;nico inform&aacute;ndole el status de su solicitud.</div>`,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              document.querySelector("#swal2-title").className +=
                " swal2-title-left";
            },
          });
        })
        .catch(() => console.log("Error"));
    },
    clickDocumento(id) {
       this.querySearch(id);
    },
    querySearch(query) {
      for (let i in this.documentos) this.documentos[i].active = false;
      const index = this.documentos.findIndex((item) => query === item.id);
      this.documentos[index].active = true;
      this.pdfdoc = this.documentos[index].url;
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  beforeCreate() {
    /* const swal = this.$swal;
      swal.fire({
        icon: "success",
        title: "El proceso ha finalizado correctamente",
        html: `<div>hola mundo</div>`,
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          
        }
      }); */
    // this.copyTodos = [...this.documentos];
  },
  async created() {
    let $self = this;
    await new Promise((resolve) => setTimeout(resolve, 4000));
    await this.apiLoadSigner()
      .then((r) => {
        let rs = r.data;
        setTimeout(() => {
          this.documentos = rs.data;
          if(this.documentos.estado =="I") return $self.$router.push(rs.data.token);
          this.pdfdoc = this.documentos[0].url;
        }, 3000);
      })
      .catch((e) => console.log(e));
  },
};
</script>

<style >
@import "../../../assets/css/visor-pdf.scss";

</style>


