<template>
  <div>
    

    <v-sheet>
      <v-toolbar style="z-index: 1">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>Transacciones</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
          transition="scroll-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text elevation="0">
              <v-avatar color="blue" size="36">
                <span class="white--text text-h6">{{
                  page_info.name | primeraLetra
                }}</span> </v-avatar
              >&nbsp;{{ page_info.name }}
              <v-icon> mdi-chevron-down </v-icon>
            </v-btn>
          </template>

          <v-card>
            
            <v-list dense>
              <v-subheader>Opciones</v-subheader>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item
                  v-for="(item, i) in itemsSubMenu"
                  :key="i"
                  @click="SubMenu(item.action)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar>

      <v-main style="height: calc(100vh - 56px); overflow: auto">
        <v-container fluid>
          <v-row align="center" justify="center">
            <v-col>
              <tabla v-if="loadingTabla"></tabla>
            </v-col>
          </v-row>
        </v-container>
      </v-main>

      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar color="blue" size="36">
                <span class="white--text text-h6">{{
                  page_info.name | primeraLetra
                }}</span> </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ page_info.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item v-for="item in items" :key="item.title" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-sheet>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<style scoped>
.container {
  max-width: 100% !important;
  padding: 15px;
}
</style>

<script>
import { tabla } from "@/core/components/panel/";
import axios from "axios";
export default {
  data() {
    return {
      loadingTabla: false,
      closeOnContentClick: true,
      overlay: false,
      fav: true,
      menu: false,
      message: false,
      hints: true,
      drawer: false,
      page_info: {
        name: null,
      },
      items: [{ title: "Transacciones", icon: "mdi-format-list-numbered " }],
      selectedItem: 0,
      itemsSubMenu: [
        { text: "Cerrar Session", icon: "mdi-logout", action: "close-session" },
      ],
      mini: true,
    };
  },
  components: {
    tabla,
  },
  filters: {
    primeraLetra: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0);
    },
  },
  methods: {
    SubMenu(v) {
      console.log(v);
      if (v == "close-session") {
        this.menu = !this.menu;
        this.overlay = !this.overlay;
        setTimeout(() => {
          localStorage.removeItem("subscription");
          localStorage.removeItem("access_token");
          this.$router.push("/auth");
        }, 3000);
      }
    },
    async validarToken() {
      const article = {};
      const response = await axios
        .post(this.$apis.accesstoken, article)
        .catch((error) => error.response);
      if (response.status != 200) {
        if (response.status == 500)
          return this.stopSecuence("Error en el servidor");
        else if (response.status == 401)
          return this.stopSecuence("No tiene autorización");
        else if (response.status == 404)
          return this.stopSecuence(
            !response.data.message
              ? "No hay conexión con el servicio"
              : response.data.message
          );
        else return this.stopSecuence(response.data.message);
      }
      if (response.data.error) return this.stopSecuence(response.data.message);
      localStorage.setItem("access_token", response.data.access_token);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    },
  },
  async created() {
    if (!localStorage.getItem("subscription")) this.$router.push("/auth");
    else {
      this.page_info.name = localStorage.getItem("usuario").toUpperCase();
      axios.defaults.headers.common["Jcdf-Apib-Subscription-Key"] =
        localStorage.getItem("subscription");
    }
    this.validarToken();
    this.overlay = !this.overlay;
    // console.log(this.$store.state.identifier)
    setTimeout(() => {
      this.loadingTabla = true;
    }, 3000);
  },
  mounted() {},
  watch: {
    overlay(val) {
      val &&
        +setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
  },
};
</script>