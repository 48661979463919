
<template>
  <div>
    <Modal v-show="isModalVisible" @close="closeModal">
      <template v-slot:loading>
        <div v-if="!startSendOtp" class="text-center">
          <div class="contenido-loader-otp">{{ textoDinamico }}</div>
          <LoadSpin2 />
        </div>
      </template>
      <template v-slot:header v-if="startSendOtp">
        <h2 class="swal2-title text-center" style="display: block">
          Firmar con código
        </h2>
      </template>

      <template v-slot:body v-if="startSendOtp">
        <div
          class="swal2-html-container"
          style="display: block; margin: 0; margin-bottom: 2rem"
        >
          <div>
            Introduzca el código recibido en su correo electrónico y/o celular
          </div>
        </div>

        <div style="display: flex; flex-direction: row">
          <v-otp-input v-model="otp" :length="length" type="number" />
        </div>
      </template>

      <template v-slot:footer v-if="startSendOtp">
        <button
          type="button"
          class="
            text-white
            btn-salmon
            hover:btn-salmon
            focus:ring-4 focus:ring-blue-300
            font-medium
            rounded-lg
            text-sm
            px-5
            py-2.5
            text-center
            mr-2
            mb-2
            dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
            w-full
            text-xl
          "
          :disabled="!isActive"
          :class="{ 'cursor-not-allowed': !isActive }"
          @click="confirmar()"
        >
          Firmar
        </button>
        <button
          @click="closeModal()"
          type="button"
          class="
            text-gray-900
            bg-white
            border border-gray-300
            hover:bg-gray-100
            focus:ring-4 focus:ring-blue-300
            font-medium
            rounded-lg
            text-sm
            px-5
            py-2.5
            text-center
            mr-2
            mb-2
            dark:bg-gray-600
            dark:text-white
            dark:border-gray-600
            dark:hover:bg-gray-700
            dark:hover:border-gray-700
            dark:focus:ring-gray-800
            w-full
            text-lg
          "
        >
          Cancelar
        </button>
        <div class="text-center mt-10">
          <a
            @click="reenviarOtp()"
            style="text-decoration: underline"
            class="underline cursor-pointer handler tc-salmon"
            :disabled="loadSendOtp"
            :class="{ 'cursor-not-allowed': loadSendOtp }"
            >Reenviar OTP

            <font-awesome-icon
              v-show="loadSendOtp"
              :icon="['fas', 'spinner']"
              spin
            />
            <span v-show="!loadSendOtp"><i class="fa fa-envelope"></i></span>
          </a>
        </div>
      </template>
    </Modal>
    <div class="grid md:grid-cols-3 lg:grid-cols-4">
      <div class="">
        <div
          class="flex items-center pb-3"
          style="
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
          "
        >
          <img
            :src="logo"
            alt="Logo"
            class="h-6 ml-4 mt-3"
            draggable="false"
            style="margin-bottom: 2px"
          />

          <v-app class="btn-custom-right">
            <v-bottom-sheet v-model="sheet">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color=" btn-salmon hover:btn-salmon  visible  md:invisible"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <i class="fa fa-bars"></i>
                </v-btn>
              </template>
              <v-list  :disabled="pdfdocclick">
                <v-subheader v-if="documentos">Documentos</v-subheader>
                <v-subheader v-else>Cargando...</v-subheader>
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list-item
                    v-for="(doc, i) in documentos"
                    :key="`item-${i}`"
                    :disabled="selectedItem == i"
                  >
                    <v-list-item-avatar>
                      <v-avatar size="32px" doc style="border-radius:0px">
                        <i class="fas fa-file-pdf text-8xl txt-salmon"></i>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-title>{{ doc.title }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-bottom-sheet>
          </v-app>
        </div>

        <div
          class="
            contenedor-info
            hidden-responsive
            p-4
            rounded-lg
            hidden
            md:block
          "
        >
          <div class="alert alert-info">
            <p
              v-html="info"
              :class="!documentos ? 'skeleton-text skeleton-effect-wave' : ''"
            ></p>
          </div>
        </div>

        <div v-if="!documentos" class="p-4 hidden md:block">
          <PuSkeleton
            v-for="index in 3"
            :key="index"
            height="40px"
            class="skeleton-effect-wave"
          />
        </div>
        <Documento
          v-else
          v-bind:todoslist="documentos"
          v-on:emit-documento="clickDocumento"
          :disableList="pdfdocclick"
          class="hidden md:block"
        />

        <div class="p-4 hidden md:block" v-show="documentos">
          <btnFirma
            v-on:firmar-documento="iniciarFirma()"
            clases="w-full"
            html="<i class='fa fa-pencil'></i> Firmar"
          />
        </div>
      </div>
      <div
        class="md:col-span-2 lg:col-span-3"
        v-bind:class="{ desktop: desktop }"
        id="visorPrincipal"
      >
        <div
          v-show="!pdfdoc || pdfdocclick"
          class="loaderIframe"
          :class="{ loaderIframeItemPdf: pdfdocclick }"
        >
          <div class="container">
            <div class="row">
              <div class="col mb-3">
                <i class="fas fa-file-pdf text-8xl txt-salmon"></i>
                <LoadSpin3 class="txt-salmon" />
              </div>
              <div class="w-100"></div>
            </div>
          </div>
        </div>
        <vue-pdf-app
          v-if="pdfdoc"
          theme="light"
          :config="config"
          :isSidebarHidden="hideSidebar"
          :pdf="$apis.showPdf + '/' + pdfdoc"
          @open="beforeCreatePdf"
        ></vue-pdf-app>
      </div>
      <div id="carbonads" class="visible md:invisible">
        <BtnFirma
          v-if="documentos"
          v-on:firmar-documento="iniciarFirma()"
          clases="shadow text-xl"
          html="<i class='fa fa-pencil text-base'></i> Firmar"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  Documento,
  BtnFirma,
  LoadSpin2,
  LoadSpin3,
  Modal,
} from "@/core/components/firmador/";
import axios from "axios";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
export default {
  name: "vista-firmador",
  components: {
    Documento,
    VuePdfApp,
    BtnFirma,
    Modal,
    LoadSpin2,
    LoadSpin3,
  },
  data() {
    return {
      otp: "",
      length: 6,
      desktop: false,
      hideSidebar: false,
      selectedItem: 0,
      sheet: false,
      pdfdocclick: false,
      tiles: [
        { img: "keep.png", title: "Keep" },
        { img: "inbox.png", title: "Inbox" },
        { img: "hangouts.png", title: "Hangouts" },
        { img: "messenger.png", title: "Messenger" },
        { img: "google.png", title: "Google+" },
      ],
      isModalVisible: false,
      dataModal: "",
      pdfdoc: null,
      logo: require("../../../assets/img/logo.png"),
      logoPrealoader: require("../../../assets/img/logo-blanco-preloader.svg"),
      currentComponent: "Pasosdelproceso",
      info: `Estimado usuario.<br />
            Su proceso de validación biométrica ha finalizado de forma exitosa.
            A continuación debe realizar el proceso de firma de los contratos
            resultantes de su proceso de vinculación.`,
      documentos: null,
      config: {
        secondaryToolbar: false,
        toolbar: {
          toolbarViewerLeft: false,
          toolbarViewerRight: false,
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
          },
        },
        errorWrapper: true,
        scrollVertical: "visible",
        scrollWrapped: "true",
      },
      startSendOtp: false,
      loadSendOtp: false,
      buttonDisabled: true,
      textoDinamico: "Cargando",
      clickoldpfd: 0,
      lockSelection: false
    };
  },
  methods: {
    showDocumentos: function () {
      const swal = this.$swal;
      swal.fire({
        title: "Documentos",
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Cerrar",
        confirmButtonAriaLabel: "Cerrar",
        html: `<div class="h-52 text-left">
                  <a href="javascript:;" id="btnA" data-document="../../documentos/userid/flujo/91234567_Contrato.pdf" class="list-group-item"><i class="fa fa-file-pdf"></i> Contrato.pdf </a>
                  <a href="javascript:;" id="btnB" data-document="../../documentos/userid/flujo/91234567_DeclaracionMercados.pdf" class="list-group-item"><i class="fa fa-file-pdf"></i> DeclaracionMercados.pdf </a>
                  <a href="javascript:;" id="btnC" data-document="../../documentos/userid/flujo/91234567_DeclaracionResidencia.pdf" class="list-group-item"><i class="fa fa-file-pdf"></i> DeclaracionResidencia.pdf</a>
                  <a href="javascript:;" data-document="../../documentos/userid/flujo/91234567_Perfil.pdf" class="list-group-item"><i class="fa fa-file-pdf"></i> Perfil.pdf</a>
              </div>`,
        didOpen: () => {
          let btnA = document.querySelector("#btnA");
          btnA.addEventListener("click", function (event) {
            event.preventDefault();
            swal.close();
          });
        },
      });
    },
    async iniciarFirma() {
      this.textoCambiante();
      this.showModal();
      if (!this.startSendOtp) {
        await this.sendOtp();
        this.startSendOtp = !this.startSendOtp;
      }
    },
    async reenviarOtp() {
      let $self = this;
      if (!this.loadSendOtp) {
        this.loadSendOtp = !this.loadSendOtp;
        await this.sendOtp().then(() => {
          $self.loadSendOtp = !$self.loadSendOtp;
        });
      }
    },
    sendOtp() {
      let $self = this;
      return new Promise(function (resolve, reject) {
        let idparam = $self.$route.params.token;
        const article = { token: idparam };
        axios
          .post($self.$apis._SendOtp, article)
          .then((r) => {
            let rs = r.data;
            $self.toastAlert("success", `C&oacute;digo OTP enviado.`, 3000);
            resolve(rs);
          })
          .catch(() => reject("error"));
      });
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    confirmar() {
      let $self = this;
      this.closeModal();
      let idparam = $self.$route.params.token;
      const article = { token: idparam, otp: this.otp };
      this.sendAlert("Firma", "Iniciando firma de contratos");
      axios
        .post($self.$apis._SignContract, article)
        .then((r) => {
          let rs = r.data;
          if (rs.error) return $self.errorAlert("Alerta", rs.message);
          let data = rs.data;
          $self.alertSuccessFullFinish(data);
          setTimeout(() => document.querySelector("#app").remove(), 1500);
        })
        .catch(() => {
          $self.errorAlert(
            "Error",
            "Ocurrió un error al enviar la solicitud de firma"
          );
        });
    },
    clickDocumento(id) {
      if (undefined !== id) {
        if (this.clickoldpfd != id) this.pdfdocclick = true;
      }

      if (id !== undefined) this.querySearch(id);
      this.clickoldpfd = id;
    },
    beforeCreatePdf() {
      this.pdfdocclick = false;
    },
    querySearch(query) {
      for (let i in this.documentos) this.documentos[i].active = false;
      const index = this.documentos.findIndex((item) => query === item.id);
      this.documentos[index].active = true;
      this.pdfdoc = this.documentos[index].pdf;
      let sidebar = document
        .getElementById("sidebarToggle")
        .classList.contains("toggled");
      if (sidebar) document.getElementById("sidebarToggle").click();
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    textoCambiante() {
      let $self = this;
      let estado = 0;
      const time = setInterval(() => {
        if (estado == 0) {
          $self.textoDinamico = "Solicitando";
          estado = 1;
        } else {
          $self.textoDinamico = "OTP";
          estado = 0;
        }
        if ($self.startSendOtp) {
          clearInterval(time);
        }
      }, 5000);
    },
    detectarMobile() {
      let details = navigator.userAgent;
      let regexp = /android|iphone|kindle|ipad/i;
      let isMobileDevice = regexp.test(details);
      if (isMobileDevice) this.desktop = false;
      else this.desktop = true;
    },
    alertSuccessFullFinish(data) {
      const swal = this.$swal;

      swal.fire({
        icon: "success",
        imageUrl: this.logo,
        imageHeight: 36,
        title: data.title,
        html: data.content,
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          let swallimage = document.querySelector(".swal2-image");
          let swallhtmlcontainer = document.querySelector(
            "#swal2-html-container"
          );
          let swalltitle = document.querySelector("#swal2-title");
          let swallicon = document.querySelector(".swal2-icon");
          swallimage.style.display = "none";
          swallhtmlcontainer.style.display = "none";
          swalltitle.style.display = "none";
          setTimeout(() => {
            swallicon.className += " animate__animated animate__fadeOutUp";
          }, 1000);
          setTimeout(() => {
            swallicon.style.display = "none";
            swallimage.style.display = "block";
            swallhtmlcontainer.style.display = "block";
            swalltitle.style.display = "block";
          }, 1500);
          swalltitle.className += " swal2-title-left";
        },
      });
    },
    testAlert() {
      let data = {
        title: "Completado",
        content: `<div class="text-left"><span class="text-left">Estimado(a) Usuario:</span>  <br><br> Su solicitud  de vinculaci&oacute;n ha sido enviada exitosamente al equipo de Credicorp Capital, el cual revisar&aacute; la informaci&oacute;n ingresada. Una vez finalizada la validaci&oacute;n en 48 horas h&aacute;biles, recibir&aacute; un correo  electr&oacute;nico inform&aacute;ndole el estatus de su solicitud.</div><div style="text-align:center"><br><br>
                    <a data-v-70ece027="" style="color: #fff;" type="button" class="
                                inline-flex
                                text-white
                                py-2
                                px-4
                                rounded
                                btn-salmon
                                hover:btn-salmon"
                                href="https://www.credicorpcapital.com/Peru/Paginas/NHome.aspx"> Cerrar </a>
                  </div>`,
      };
      setTimeout(() => {
        this.alertSuccessFullFinish(data);
      }, 6000);
    },
  },
  beforeCreate() {},
  async created() {
    let $self = this;
    this.detectarMobile();
    await new Promise((resolve) => setTimeout(resolve, 4000));
    await this.apiLoadSigner()
      .then((r) => {
        let rs = r.data;
        setTimeout(() => {
          $self.documentos = rs.data;
          if ($self.documentos[0]) {
            $self.pdfdoc = $self.documentos[0].pdf;
          } else {
            this.errorAlert(
              "Contrato no disponible",
              rs.message +
                "<br> Actualice la página o vuelva intentarlo más tarde",
              false,
              false
            );
            $self.documentos = false;
          }
        }, 3000);
      })
      .catch(() =>
        this.errorAlert(
          "Error",
          "No es posible descargar el documento",
          false,
          false
        )
      );
  },
  watch: {
    selectedItem: function (v) {
      this.sheet = false;
      this.clickDocumento(v);
    },
  },
  computed: {
    isActive() {
      return this.otp.length === this.length;
    },
  },
};
</script>

<style >
@import "../../../assets/css/visor-pdf.scss";
.contenido-loader-otp {
  height: 100px;
  position: absolute;
  text-align: center;
  margin: 0 auto;
  align-content: center;
  width: 118px;
  display: inline-grid;
  left: 0;
  right: 0;
}
</style>


