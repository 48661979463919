<template>
<div>
    <Preloader2 v-bind:logo="logoPrealoader" />
    <v-container
    class="fill-height justify-center"
    :class="{ 'align-start': $vuetify.breakpoint.xsOnly }"
    fluid
  >

    <login-card :loading="loading" :disabled="disabled">
      <router-view @next="onNext" />
    </login-card>
    <v-overlay :value="overlay"></v-overlay>
  </v-container>
</div>
  
</template>

<script>
import LoginCard from "@/core/components/LoginCard/LoginCard";
import {Preloader2} from "@/core/components/layout/";
import axios from "axios";
export default {
  components: { LoginCard,Preloader2 },
  data: () => ({
    overlay: false,
    loading: false,
    disabled: false,
    logo: require("./../../../assets/img/logo.png"),
    logoPrealoader: require("./../../../assets/img/logo-blanco-preloader.svg"),
  }),

  methods: {
    onNext(data) {
      this.loading = true;
      this.disabled = true;
      setTimeout(() => {
        if (data.action == "user") {
          this.loading = false;
          this.disabled = false;
          this.$router.push({ name: "password" });
        } else {
          this.login();
        }
      }, 1000);
    },
    async login() {
      const article = {
        Username: this.$store.state.identifier,
        Password: this.$store.state.password,
      };
      const response = await axios
        .post(this.$apis.suscription, article)
        .catch((error) => error.response);
      this.loading = this.disabled = false;
      if (response.status != 200) {
        if (response.status == 500)
          return this.stopSecuence("Error en el servidor");
        else if (response.status == 404)
          return this.stopSecuence(
            !response.data.message
              ? "No hay conexión con el servicio"
              : response.data.message
          );
        else return this.stopSecuence(response.data.message);
      }

      if (response.data.error) return this.stopSecuence(response.data.message);

      this.overlay = true;
      localStorage.setItem("subscription", response.data.subscription_key);
      axios.defaults.headers.common["Jcdf-Apib-Subscription-Key"] =
        localStorage.getItem("subscription");
      localStorage.setItem("expires", response.data.expires_in);
      localStorage.setItem("nombres", response.data.nombre);
      this.$router.push("/admin");
    },
    stopSecuence($msj = "") {
      this.$store.commit("updateMessageLogin", $msj);
      // this.$swal.fire("Alerta", $msj, "error");
      // this.toastAlert("info",  $msj, 2000, "bottom-end");
    },
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
  },
  created() {
    if (localStorage.getItem("usuario")) {
      this.$store.state.identifier = localStorage.getItem("usuario");

      // this.$router.push({ name: "password" });
    }
  },
};
</script>

<style lang="scss">
.letter-spacing-0 {
  letter-spacing: 0 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}
</style>
