<template>
  <v-dialog
    max-width="900"
    v-model="dialogm"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Historial de reintentos fin firma</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items class="h-auto">{{ json.id }}</v-toolbar-items>
        </v-toolbar>
        <div class="div-content-historial-finfirma">
          <template v-for="(item, index) in json.data">
            <v-list-item :key="index">
              <template v-slot:default="{}">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-simple-table :no-border="true" dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th width="100px" class="uppercase">Descripción</th>
                            <td width="10px">:</td>
                            <td>{{ item.descripcion }}</td>
                          </tr>
                          <tr>
                            <th class="uppercase">Fecha de ejecución</th>
                            <td>:</td>
                            <td>
                              {{ item.fecha_de_ejecucion }}
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" dense
                                    >mdi-information
                                  </v-icon>
                                </template>
                                <span>
                                  Hora en la que el proceso automático podrá
                                  visualizar esta programación para reintentar
                                  el envío al API Fin Firma
                                </span>
                              </v-tooltip>
                            </td>
                          </tr>
                          <tr>
                            <th class="uppercase">Fecha de completado</th>
                            <td>:</td>
                            <td v-if="item.fecha_de_cierre">
                              {{ item.fecha_de_cierre }}
                              <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" dense
                                    >mdi-information
                                  </v-icon>
                                </template>
                                <span>
                                  Hora en la que el proceso de reintento al API Fin Firma se completó
                                </span>
                              </v-tooltip>
                            </td>
                            <td v-else><i>Sin completar</i></td>
                          </tr>
                          <tr >
                            <th class="uppercase">Respuesta Fin Firma</th>
                            <td>:</td>
                            <td v-if="item.response"><v-icon dense>mdi-code-json</v-icon></td>
                            <td v-else>No hay respuesta registrada</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text
                    v-text="item.fecha_de_registro"
                  ></v-list-item-action-text>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        :color="getColorStateFinFirma(item.estado)"
                        v-bind="attrs"
                        v-on="on"
                        text-color="white"
                        small
                      >
                        <v-avatar left v-if="item.estado == 'C'">
                          <v-icon small>mdi-checkbox-marked-circle</v-icon>
                        </v-avatar>
                        <v-avatar left v-else>
                          <v-icon small>mdi-timer-sand</v-icon>
                        </v-avatar>

                        {{ item.estado | textEstadoHistorialFinFirma }}
                      </v-chip>
                    </template>
                    <span>
                      {{ item.estado | textEstadoHistorialFinFirmaDesc }}
                    </span>
                  </v-tooltip>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-card :key="index + 'b'" flat v-if="item.response">
              <v-card-text style="overflow: auto; padding-top: 0px">
                <json-viewer
                    :value="jsonConverter(item.response)"
                    :expand-depth="5"
                    :copyable="{
                      copyText: 'Copiar',
                      copiedText: 'Copiando',
                      timeout: 500,
                    }"
                  ></json-viewer>
              </v-card-text>
            </v-card>
            <v-divider :key="index + 'c'"></v-divider>
          </template>
        </div>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import menu from "./menu.js";
export default {
  props: ["title", "openmodal", "json"],
  data() {
    return {
      reverse: false,
      dialog: false,
      password: "Password",
      show: false,
      message: "Hey!",
      marker: true,
      iconIndex: 0,
      selected: [2],
      items: [
        {
          action: "15 min",
          headline: "Brunch this weekend?",
          subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
          title: "Ali Connors",
        },
        {
          action: "2 hr",
          headline: "Summer BBQ",
          subtitle: `Wish I could come, but I'm out of town this weekend.`,
          title: "me, Scrott, Jennifer",
        },
        {
          action: "6 hr",
          headline: "Oui oui",
          subtitle: "Do you have Paris recommendations? Have you ever been?",
          title: "Sandra Adams",
        },
        {
          action: "12 hr",
          headline: "Birthday gift",
          subtitle:
            "Have any ideas about what we should get Heidi for her birthday?",
          title: "Trevor Hansen",
        },
        {
          action: "18hr",
          headline: "Recipe to try",
          subtitle:
            "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
          title: "Britta Holt",
        },
      ],
    };
  },
  filters: {
    filterNumber: function (v) {
      let str = v.toString();
      let newStr = str.replace("-", "");
      return newStr;
    },
    ...menu.filters,
  },
  computed: {
    dialogm: {
      get: function () {
        return this.openmodal;
      },
      set: function (value) {
        this.tab = null;
        this.$emit("closeEvent", value);
      },
    },
    icon() {
      return this.icons[this.iconIndex];
    },
  },
  methods: {
    copyTextInput: async function (v) {
      this.copyV2(v);
      this.toastAlert("success", "Texto copiado!", 2000, "bottom-end");
      return;
    },
    ...menu.methods,
  },
};
</script>

<style >
.scope-dialog-detalle-cliente
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}
</style>