<template>
  <div class="verify">
    <Verificando
      :texto="'Validando enlace'"
      v-if="!verficandoPage"
    ></Verificando>

    <div v-else>
      <div v-if="!verficandoPage.error">
         <Preloader2 v-bind:logo="logoPrealoader" />
        <div v-if="verficandoPage.data.transaccion_estado == 'I'">
          <Header v-bind:logo="logo" />
          <transition name="fade" mode="out-in">
            <component
              :is="currentComponent"
              :swap-component="swapComponent"
              v-bind="currentProperties"
              v-on:escuchar-hijo="accionMethod"
            ></component>
          </transition>
        </div>
        <div v-if="verficandoPage.data.transaccion_estado == 'V'">
          <Signer></Signer>
        </div>
      </div>
      <div v-else>
        <TokenComponent :response="verficandoPage"></TokenComponent>
      </div>
    </div>
  </div>
</template>

<script>

import {
  Pasosdelproceso,
  Recomendaciones,
  SeleccioneDocumento,
  Camara,
} from "@/core/components/indicaciones/";
import {
  Header,
  Preloader2,
  TokenComponent,
  Verificando,
} from "@/core/components/layout/";

import Signer from "@/core/views/signer/index.vue";
export default {
  name: "App",
  data() {
    return {
      logo: require("@/assets/img/logo.png"),
      logoPrealoader: require("@/assets/img/logo-blanco-preloader.svg"),
      currentComponent: "Pasosdelproceso",
      tipoDocumento: null,
      verficandoPage: false,
      datareader: null,
      estadoPage: null
    };
  },
  computed: {
    currentProperties: function () {
      return { 
        tipoDocumento: this.tipoDocumento,
      };
    },
  },
  components: {
    Preloader2: Preloader2,
    Header: Header,
    Pasosdelproceso: Pasosdelproceso,
    Recomendaciones: Recomendaciones,
    SeleccioneDocumento: SeleccioneDocumento,
    Camara: Camara,
    Verificando: Verificando,
    TokenComponent: TokenComponent,
    Signer:Signer
  },

  methods: {
    swapComponent: function (component) {
      this.currentComponent = component;
    },
    accionMethod (v){
      this.verficandoPage = v;
    }
  },
  async mounted() {
    await new Promise((resolve) => setTimeout(resolve, 4000));

    this.apiVerifyToken()
      .then((r) => {
        let rs = r.data;
        /* if (typeof rs.data !== "undefined") {
          if (rs.data.estado == "V") return this.$router.push(rs.data.token);
        } */
        this.verficandoPage = rs;
      })
      .catch(() => this.$router.push("/access-denied"));
  },
  async created() {
    let idparam = this.$route.params.token;
    if (idparam == "") this.$router.push("/notfount");
  },
};
</script>

<style>

span.text-red-500 {
    color: #ea6752;
}

.tc-salmon {
  color: #ea6752;
}

.btn-salmon {
  background: #ea6752;
  color: #fff;
}

.btn-salmon:hover {
  background: #e06451;
  color: #fff;
}

.btn-salmon-secondary {
  background: transparent;
  color: #ea6752;
}

.btn-salmon-secondary:hover {
  background: #e06451;
  color: #fff;
}

.tc-gris-oscuro {
  color: #54565b;
}

.tc-gris-claro {
  color: #8f9c9d;
}

.tc-azul {
  color: #26334a;
}

.tc-celest {
  color: #7aa2d6;
}

.tc-ladrillo {
  color: #402020;
}

.btn-gris-claro {
  background: #8f9c9d;
  color: #54565b;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-leave-active {
  transition: 0.3s;
}
.slide-enter-active {
  transition: 0.1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
</style>
