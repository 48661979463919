var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"900","fullscreen":_vm.$vuetify.breakpoint.xsOnly},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Historial de reintentos fin firma")]),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"h-auto"},[_vm._v(_vm._s(_vm.json.id))])],1),_c('div',{staticClass:"div-content-historial-finfirma"},[_vm._l((_vm.json.data),function(item,index){return [_c('v-list-item',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('v-simple-table',{attrs:{"no-border":true,"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('th',{staticClass:"uppercase",attrs:{"width":"100px"}},[_vm._v("Descripción")]),_c('td',{attrs:{"width":"10px"}},[_vm._v(":")]),_c('td',[_vm._v(_vm._s(item.descripcion))])]),_c('tr',[_c('th',{staticClass:"uppercase"},[_vm._v("Fecha de ejecución")]),_c('td',[_vm._v(":")]),_c('td',[_vm._v(" "+_vm._s(item.fecha_de_ejecucion)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(" Hora en la que el proceso automático podrá visualizar esta programación para reintentar el envío al API Fin Firma ")])])],1)]),_c('tr',[_c('th',{staticClass:"uppercase"},[_vm._v("Fecha de completado")]),_c('td',[_vm._v(":")]),(item.fecha_de_cierre)?_c('td',[_vm._v(" "+_vm._s(item.fecha_de_cierre)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(" Hora en la que el proceso de reintento al API Fin Firma se completó ")])])],1):_c('td',[_c('i',[_vm._v("Sin completar")])])]),_c('tr',[_c('th',{staticClass:"uppercase"},[_vm._v("Respuesta Fin Firma")]),_c('td',[_vm._v(":")]),(item.response)?_c('td',[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-code-json")])],1):_c('td',[_vm._v("No hay respuesta registrada")])])])]},proxy:true}],null,true)})],1)],1),_c('v-list-item-action',[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(item.fecha_de_registro)}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":_vm.getColorStateFinFirma(item.estado),"text-color":"white","small":""}},'v-chip',attrs,false),on),[(item.estado == 'C')?_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-marked-circle")])],1):_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-timer-sand")])],1),_vm._v(" "+_vm._s(_vm._f("textEstadoHistorialFinFirma")(item.estado))+" ")],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("textEstadoHistorialFinFirmaDesc")(item.estado))+" ")])])],1)]}}],null,true)}),(item.response)?_c('v-card',{key:index + 'b',attrs:{"flat":""}},[_c('v-card-text',{staticStyle:{"overflow":"auto","padding-top":"0px"}},[_c('json-viewer',{attrs:{"value":_vm.jsonConverter(item.response),"expand-depth":5,"copyable":{
                    copyText: 'Copiar',
                    copiedText: 'Copiando',
                    timeout: 500,
                  }}})],1)],1):_vm._e(),_c('v-divider',{key:index + 'c'})]})],2),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cerrar")])],1)],1)]}}]),model:{value:(_vm.dialogm),callback:function ($$v) {_vm.dialogm=$$v},expression:"dialogm"}})}
var staticRenderFns = []

export { render, staticRenderFns }