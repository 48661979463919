import XLSX from "vue-excel-xlsx/node_modules/xlsx/xlsx";
export default {
    data: {
        dialogLoader: false,
        items: [{
                title: "Copiar Token Web Completo",
                icon: "mdi-content-copy",
                action: "copyTokenPage2",
            },
            {
                title: "Copiar Token Web",
                icon: "mdi-content-copy",
                action: "copyTokenPage",
            },
            { divider: true },
            {
                title: "Detalles del cliente",
                icon: "mdi-account-details",
                action: "detalle-cliente",
            },
            {
                title: "Detalles del dispositivo (biometría)",
                icon: "mdi-details",
                action: "detalleDispositivo",
            },
            {
                title: "Incidencias",
                icon: "mdi-timeline-text-outline",
                action: "incidencias",
            },
            {
                title: "Body para consumir Fin Firma",
                icon: "mdi-code-json",
                action: "logDataFinFirma",
            },
            { divider: true },
            {
                title: "Reintentar Fin Firma",
                icon: "mdi-reload",
                action: "reintentar-finfirma",
            },
            {
                title: "Historial de Reintentos",
                icon: "mdi-clipboard-text-clock",
                action: "historial-finfirma",
            },
            { divider: true },
            {
                title: "Log Biometría",
                icon: "mdi-code-json",
                action: "logBiometryc",
            },
            {
                title: "Log Fin Firma",
                icon: "mdi-code-json",
                action: "logResponseFinFirma",
            },
        ],
    },
    methods: {
        row_classes(item) {
            if (item.incidencias > 0) {
                return "orange"; //can also return multiple classes e.g ["orange","disabled"]
            }
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map((v) => filterVal.map((j) => v[j]));
        },
        copy(text) {
            let elem = document.createElement("textarea");
            elem.value = text;
            document.body.appendChild(elem);
            elem.select();
            document.execCommand("copy");
            document.body.removeChild(elem);
        },
        copyV2(v) {
            navigator.clipboard.writeText(v);
        },
        isObject(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        getNestedValue(object, string) {
            string = string.replace(/\[(\w+)\]/g, ".$1");
            string = string.replace(/^\./, "");
            let a = string.split(".");
            for (let i = 0, n = a.length; i < n; ++i) {
                let k = a[i];
                if (k in object) {
                    object = object[k];
                } else {
                    return;
                }
            }
            return object;
        },
        exportExcel(vm) {
            let createXLSLFormatObj = [];
            let newXlsHeader = [];
            if (vm.columns.length === 0) {
                return;
            }
            if (vm.data.length === 0) {
                return;
            }
            vm.columns.map((column) => {
                newXlsHeader.push(column.text);
            });

            createXLSLFormatObj.push(newXlsHeader);
            vm.data.map((value) => {
                let innerRowData = [];
                vm.columns.map((val) => {
                    let fieldValue = value[val.value];
                    if (val.value.split(".").length > 1) {
                        fieldValue = this.getNestedValue(value, val.value);
                    }
                    if (val.dataFormat && typeof val.dataFormat === "function") {
                        innerRowData.push(val.dataFormat(fieldValue));
                    } else {
                        innerRowData.push(fieldValue);
                    }
                });
                createXLSLFormatObj.push(innerRowData);
            });

            let fileName = vm.fileName + "." + vm.fileType;

            let ws_name = vm.sheetName;

            let wb = XLSX.utils.book_new(),
                ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
            XLSX.utils.book_append_sheet(wb, ws, ws_name);
            XLSX.writeFile(wb, fileName);
        },
        getColorState(value) {
            if (value == "C") return "green";
            else if (value == "V") return "orange";
            else if (value == "I") return "light blue";
            else return "red";
        },
        getColorStateFinFirma(value) {
            if (value == "C") return "green";
            else if (value == "E") return "light blue";
            else if (value == "P") return "orange";
            else if (value == "T") return "primary";
            else if (value == "F") return "red";
            else if (value == "R") return "red";
            else return "red";
        },
        jsonConverter: function(v) {
            let data = v;
            if (v) data = JSON.parse(data);
            return data;
        }
    },
    filters: {
        textEstado: function(value) {
            let texto = "";
            if (value == "C") texto = "Completado";
            else if (value == "V") texto = "Verificado";
            else if (value == "I") texto = "Pendiente";
            else texto = "Error";
            return texto;
        },
        textEstadoDesc: function(value) {
            let texto = "";
            if (value == "C")
                texto = "El cliente a completado todo el proceso de firma";
            else if (value == "V") texto = "El cliente fue verificado exitosamente";
            else if (value == "I")
                texto = "Iniciado por credicorp y pendiente por el cliente";
            else texto = "Aún no definido";
            return texto;
        },
        textEstadoFinFirma: function(value) {
            let texto = "";
            if (value == "C") texto = "Completado";
            else if (value == "E") texto = "En Espera";
            else if (value == "P") texto = "Procesando";
            else if (value == "T") texto = "Procesando";
            else if (value == "F") texto = "Fallido";
            else if (value == "R") texto = "Procesando";
            else texto = "Error no identificado";
            return texto;
        },
        textEstadoFinFirmaDesc: function(value) {
            let texto = "";
            if (value == "C") texto = "Envío de documentos completado correctamente";
            else if (value == "E")
                texto =
                "Esperando que el cliente complete su proceso de biometría y firma";
            else if (value == "P") texto = "Procesando para Fin Firma (en cola)";
            else if (value == "R")
                texto = "Procesando reintento para Fin Firma (en cola)";
            else if (value == "T") texto = "Procesando para Fin Firma (Tiempo real)";
            else if (value == "F")
                texto = "Envío de documentos completado con errores";
            else texto = "Error no identificado";
            return texto;
        },
        textEstadoHistorialFinFirma: function(value) {
            let texto = "";
            if (value == "C") texto = "Completado";
            else if (value == "P") texto = "Procesando";
            else if (value == "E") texto = "En espera";
            else texto = "Error no identificado";
            return texto;
        },
        textEstadoHistorialFinFirmaDesc: function(value) {
            let texto = "";
            if (value == "C") texto = "Reintento programado completado";
            else if (value == "P") texto = "Procesando reintento";
            else if (value == "E") texto = "Reintento en espera de ser ejecutado";
            else texto = "Error no identificado";
            return texto;
        },

    },
    watch: {
        dialogLoader(val) {
            if (!val) return;

            setTimeout(() => (this.dialogLoader = false), 4000);
        },
    },
};