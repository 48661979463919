<template>
  <v-dialog
    max-width="900"
    v-model="dialogm"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Detalles del cliente </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items class="h-auto">{{ json.id }}</v-toolbar-items>
        </v-toolbar>
        <div class="div-content-detalle-cliente">
          <v-form>
            <v-container fluid>
              <v-row no-gutters>
                <v-col cols="3">
                  <v-subheader class="font-medium">ID Transacción</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="json.data.idtransaccion"
                    :append-icon="'mdi-content-copy'"
                    filled
                    type="text"
                    @click:append="copyTextInput(json.data.idtransaccion)"
                    readonly
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-subheader class="font-medium">Nombre</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="json.data.nombre"
                    :append-icon="'mdi-content-copy'"
                    filled
                    type="text"
                    @click:append="copyTextInput(json.data.nombre)"
                    readonly
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-subheader class="font-medium">Nombre otros</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="json.data.nombre_otros"
                    :append-icon="'mdi-content-copy'"
                    filled
                    type="text"
                    @click:append="copyTextInput(json.data.nombre_otros)"
                    readonly
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-subheader class="font-medium"
                    >Apellido Paterno</v-subheader
                  >
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="json.data.apellido_pat"
                    :append-icon="'mdi-content-copy'"
                    filled
                    type="text"
                    @click:append="copyTextInput(json.data.apellido_pat)"
                    readonly
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-subheader class="font-medium"
                    >Apellido Materno</v-subheader
                  >
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="json.data.apellido_mat"
                    :append-icon="'mdi-content-copy'"
                    filled
                    type="text"
                    @click:append="copyTextInput(json.data.apellido_mat)"
                    readonly
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-subheader class="font-medium"
                    >Fecha de nacimiento</v-subheader
                  >
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="json.data.fechanac"
                    :append-icon="'mdi-content-copy'"
                    filled
                    type="text"
                    @click:append="copyTextInput(json.data.fechanac)"
                    readonly
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-subheader class="font-medium">ID Cliente</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="json.data.id_cliente"
                    :append-icon="'mdi-content-copy'"
                    filled
                    type="text"
                    @click:append="copyTextInput(json.data.id_cliente)"
                    readonly
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-subheader class="font-medium"
                    >Correo Electrónico</v-subheader
                  >
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="json.data.email"
                    :append-icon="'mdi-content-copy'"
                    filled
                    type="text"
                    @click:append="copyTextInput(json.data.email)"
                    readonly
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-subheader class="font-medium">Telefono</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="json.data.telefono"
                    :append-icon="'mdi-content-copy'"
                    filled
                    type="text"
                    @click:append="copyTextInput(json.data.telefono)"
                    readonly
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-subheader class="font-medium">País</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="json.data.pais"
                    :append-icon="'mdi-content-copy'"
                    filled
                    type="text"
                    @click:append="copyTextInput(json.data.pais)"
                    readonly
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-subheader class="font-medium">Tipo de cliente</v-subheader>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="json.data.tipo_cliente"
                    :append-icon="'mdi-content-copy'"
                    filled
                    type="text"
                    @click:append="copyTextInput(json.data.tipo_cliente)"
                    readonly
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="font-medium">Tipo id cliente</v-subheader>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="json.data.tipo_id_cliente"
                    :append-icon="'mdi-content-copy'"
                    filled
                    type="text"
                    @click:append="copyTextInput(json.data.tipo_id_cliente)"
                    readonly
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="scope-dialog-detalle-cliente">
                  <v-simple-table :hover="false">
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="font-medium" style="color: #828282">
                            Estado Cliente
                          </td>
                          <td>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  :color="getColorState(json.data.estado)"
                                  v-bind="attrs"
                                  v-on="on"
                                  text-color="white"
                                  small
                                >
                                  <v-avatar left v-if="json.data.estado == 'C'">
                                    <v-icon small
                                      >mdi-checkbox-marked-circle</v-icon
                                    >
                                  </v-avatar>
                                  <v-avatar left v-if="json.data.estado == 'V'">
                                    <v-icon small>mdi-face-recognition</v-icon>
                                  </v-avatar>
                                  {{ json.data.estado | textEstado }}
                                </v-chip>
                              </template>
                              <span>
                                {{ json.data.estado | textEstadoDesc }}</span
                              >
                            </v-tooltip>
                          </td>
                          <td class="font-medium" style="color: #828282">
                            Estado Fin Firma
                          </td>
                          <td>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  :color="
                                    getColorStateFinFirma(
                                      json.data.estado_final
                                    )
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                  text-color="white"
                                  small
                                >
                                  <v-avatar
                                    left
                                    v-if="json.data.estado_final == 'T'"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      :size="15"
                                      width="3"
                                      :value="50"
                                    ></v-progress-circular>
                                  </v-avatar>
                                  <v-avatar
                                    left
                                    v-if="json.data.estado_final == 'C'"
                                  >
                                    <v-icon small
                                      >mdi-checkbox-marked-circle</v-icon
                                    >
                                  </v-avatar>
                                  <v-avatar
                                    left
                                    v-if="json.data.estado_final == 'P'"
                                  >
                                    <v-icon small>mdi-timer-sand</v-icon>
                                  </v-avatar>
                                  <v-avatar
                                    left
                                    v-if="json.data.estado_final == 'F'"
                                  >
                                    <v-icon small>mdi-alert-circle</v-icon>
                                  </v-avatar>

                                  {{
                                    json.data.estado_final | textEstadoFinFirma
                                  }}
                                </v-chip>
                              </template>
                              <span>
                                {{
                                  json.data.estado_final
                                    | textEstadoFinFirmaDesc
                                }}
                              </span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </div>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import menu from "./menu.js";
export default {
  props: ["title", "openmodal", "json"],
  data() {
    return {
      reverse: false,
      dialog: false,
      password: "Password",
      show: false,
      message: "Hey!",
      marker: true,
      iconIndex: 0,
      icons: [
        "mdi-emoticon",
        "mdi-emoticon-cool",
        "mdi-emoticon-dead",
        "mdi-emoticon-excited",
        "mdi-emoticon-happy",
        "mdi-emoticon-neutral",
        "mdi-emoticon-sad",
        "mdi-emoticon-tongue",
      ],
    };
  },
  filters: {
    filterNumber: function (v) {
      let str = v.toString();
      let newStr = str.replace("-", "");
      return newStr;
    },
    ...menu.filters,
  },
  computed: {
    dialogm: {
      get: function () {
        return this.openmodal;
      },
      set: function (value) {
        this.tab = null;
        this.$emit("closeEvent", value);
      },
    },
    icon() {
      return this.icons[this.iconIndex];
    },
  },
  methods: {
    copyTextInput: async function (v) {
      this.copyV2(v);
      this.toastAlert("success", "Texto copiado!", 2000, "bottom-end");
      return;
    },
    ...menu.methods,
  },
};
</script>

<style >
.scope-dialog-detalle-cliente
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}
</style>