<template>
  <v-dialog
    max-width="700"
    v-model="dialogm"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Detalles del dispositivo (biometría)</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="h-auto">{{json.id}}</v-toolbar-items>
        </v-toolbar>
        <div class="tab-custom-details-device">
         <v-tabs vertical>
              <template v-for="(item, index) in json.data">
                <v-tab :key="index">
                  <v-icon left> mdi-cellphone </v-icon>{{
                    (index - json.data.length) | filterNumber
                  }}
                </v-tab>
                <v-tab-item :key="index">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="( value, property) in item" :key="property">
                          <td>{{ property }}</td>
                          <td>:</td>
                          <td>{{value}}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>
              </template>
            </v-tabs>
        </div>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: ["title", "openmodal", "json"],
  data() {
    return {
      dialog: false,
      desserts: [
        {
          name: "Sistema Operativo",
          calories: "Android",
        },
        {
          name: "Navegador",
          calories: "CHROME 101.0.4951.61",
        },
        {
          name: "IP",
          calories: "192.168.1.35",
        },
        {
          name: "Fecha de registro",
          calories: "",
        },
      ],
    };
  },
  computed: {
    dialogm: {
      get: function () {
        return this.openmodal;
      },
      set: function (value) {
        this.tab = null;
        this.$emit("closeEvent", value);
      },
    },
  },
  filters: {
    filterNumber: function (v) {
      let str = v.toString();
      let newStr = str.replace("-", "");
      return newStr;
    },
  },
};
</script>