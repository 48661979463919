<template>
  <div class="flex items-center justify-center w-screen h-screen ">
    <div class="flex flex-col text-center">
      <div v-show="load" class="py-5 animate__animated animate__fadeInDown">
        <img :src="logo" draggable="false" class="w-80 margin-0-auto" id="logoVerificando" />
      </div>

      <div
        v-if="load"
        class="animate__animated animate__fadeInDown animation-0s2"
      >
        <p class="text-2xl font-normal leading-normal mt-0 mb-2 tc-gris-oscuro">
          {{ texto }}
        </p>
      </div>

      <div
        v-if="load"
        class="
          py-4
          text-center
          animate__animated animate__fadeInDown
          animation-0s4
        "
      >
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <i class="icon-camera-3 invisible" ></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Verificando",
  props: ["texto"],
  data() {
    return {
      logo: require("./../../../assets/img/logo.png"),
      logoPrealoader: require("./../../../assets/img/logo-blanco-preloader.svg"),
      load: false,
      configAnimation: {
        text1: {
          classes: "fadeInDown",
          delay: 1000,
        },
        text2: {
          classes: "fadeInDown",
          delay: 1500,
        },
        text3: {
          classes: "fadeInDown",
          delay: 2000,
        },
      },
    };
  },
  created() {
    // setTimeout(() => (this.load = true), 500);
  },
  mounted() {
    const cover = document.querySelector("#logoVerificando");
    cover.onload = () => {
      this.load = true;
    };
  },
};
</script>

<style scoped>
.hide-prev-animation {
  visibility: hidden;
}
.border-credicorp-gris {
  --tw-border-opacity: 1;
  border-color: rgba(85, 88, 93, var(--tw-text-opacity));
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 55px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fdd;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fdd transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
