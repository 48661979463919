import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
Vue.use(VueRouter);

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
import {
    PageNotFound,
    PageTokenNoValid,
    Indicaciones,
    Firmador,
    Loading,
    Home,
    Credicorp,
} from "../core/views/";
// import auth from "../logic/auth.js";

import Auth from "@/core/views/auth/Auth";
import Signin from "@/core/views/auth/Signin";
import SigninIdentifier from "@/core/views/auth/SigninIdentifier";
import SigninPassword from "@/core/views/auth/SigninPassword";
import Signup from "@/core/views/auth/Signup";
import panel from "@/core/views/panel";

import jsonViewer from "@/core/views/panel/json-viewer";

/* beforeEnter: async function(to, from, next) {
    var isAuthenticated = true;
    let data = { token: to.params.token };
    await Vue.prototype.apiLoadSigner(data)
        .then((r) => {
            let rs = r.data;
            console.log(rs);
            if (isAuthenticated) next();
            else next('/login/' + data.token);

        })
        .catch((e) => console.log(e));

}, */

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/verify/:token",
        name: "Verify",
        component: Credicorp,
    },
    {
        path: "/auth",
        component: Auth,
        redirect: "/auth/signin",
        children: [{
                path: "signin",
                redirect: "/auth/signin/identifier",
                component: Signin,
                children: [{
                        path: "identifier",
                        name: "signin",
                        component: SigninIdentifier,
                    },
                    {
                        path: "password",
                        name: "password",
                        component: SigninPassword,
                    },
                ],
            },
            {
                path: "signup",
                component: Signup,
                name: "signup",
            },
        ],
    },
    {
        path: "/admin",
        name: "admin",
        component: panel,
    },
    {
        path: "/json-viewer",
        name: "json-viewer",
        component: jsonViewer,
    },
    {
        path: "/verify-old/:token",
        name: "Verify-old",
        component: Indicaciones,
        params: {
            id: 9999999999,
        },
        query: { id: "foo" },
        props: (route) => ({ foo: route.query.id }),
    },
    {
        path: "/signer/:token",
        name: "Firmador",
        component: Firmador,
    },
    {
        path: "/contract/:token",
        name: "Contrato",
        component: Loading,
        beforeEnter: async function(to, from, next) {
            next();
            let data = { token: to.params.token };
            await Vue.prototype
                .apiVerifyToken(data)
                .then((r) => {
                    let rs = r.data;
                    console.log(rs);
                    if (typeof rs.data !== "undefined") {
                        next(rs.data.token);
                        sessionStorage.setItem("key", JSON.stringify(rs.data.token));
                    }
                })
                .catch((e) => console.log(e));
        },
        children: [{
                path: "/contract/:token/verify",
                component: Indicaciones,
            },
            {
                path: "/contract/:token/signer",
                component: Firmador,
            },
        ],
    },
    {
        path: "/access-denied",
        name: "access-denied",
        component: PageTokenNoValid,
    },
    {
        path: "*",
        component: PageNotFound,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

/* router.beforeEach((to, from, next) => {
    // if (to.name !== 'login' && !isAuthenticated) {
    let data = false;
    if (data) {
        next({ name: 'login' });
    } else {
        next();
    }
});
 */
export default router;