<template>
<v-list :disabled="disableList" :class="{'bloquear-custom':disableList}">
<div
    
      v-bind:key="todo.id"
      v-for="todo in todoslist"
      style="text-align: left; font-size: 14px"
    >
      <DocumentoItem
        v-bind:todo="todo"
        v-on:item-documento="procesandoClickHijo(todo.id)"
        style=""
      />
    </div>
</v-list>
 
  <!-- <section class="list-group menu-message list-group-active hidden-responsive">
        <div v-bind:key="todo.id" v-for="todo in todoslist" 
          style="text-align: left; font-size: 14px">
            <DocumentoItem v-bind:todo="todo" v-on:item-documento="$emit('emit-documento', todo.id)" style=""/>
        </div>
    </section> -->
</template>

<script>
import DocumentoItem from "./DocumentoItem.vue";
export default {
  name: "Todos",
  props: ["todoslist","disableList"],
  components: { DocumentoItem },
  methods: {
      procesandoClickHijo(v){
          this.$emit('emit-documento', v);
      }
  }
};
</script>

<style></style>