<template>
  <v-dialog max-width="800" v-model="dialogm" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Incidencias </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items class="h-auto">{{json.id}}</v-toolbar-items>
        </v-toolbar>
        <div class="div-content-detalle-cliente">
          <v-timeline dense>
            <template v-for="(item, index) in json.data">
              <v-timeline-item
                :key="index"
                :color="item.color"
                small
                :large="index == 0"
              >
                <template v-slot:icon>
                  <span style="color: white">{{
                    (index - json.data.length) | filterNumber
                  }}</span></template
                >
                <template v-slot:opposite>
                  <span v-html="item.fecha"></span>
                </template>
                <v-card elevation="1">
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12"  md="9">
                        <strong v-html="item.descripcion"></strong>
                      </v-col>
                      <v-col cols="12"  md="3"> <div class="text-caption " v-html="item.fecha"></div></v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </template>
          </v-timeline>
        </div>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: ["title", "openmodal", "json"],
  data() {
    return {
      reverse: false,
      dialog: false,
      desserts: [
        {
          color: "teal lighten-3",
          fecha: "31/05/2022 4:14 PM",
          titulo: "Visitando pagina",
          descripcion: "success",
        },
        {
          color: "pink",
          fecha: "31/05/2022 5:14 PM",
          titulo: "Lunch break",
          descripcion: "success",
        },
        {
          color: "teal lighten-3",
          fecha: "31/05/2022 6:14 PM",
          titulo: "Finish Home Screen",
          descripcion: "Web App",
        },
      ],
    };
  },
  filters: {
    filterNumber: function (v) {
      let str = v.toString();
      let newStr = str.replace("-", "");
      return newStr;
    },
  },
  computed: {
    dialogm: {
      get: function () {
        return this.openmodal;
      },
      set: function (value) {
        this.tab = null;
        this.$emit("closeEvent", value);
      },
    },
  },
};
</script>