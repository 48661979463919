<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
  >
    <div style="background: #fff; height: 100%">
      <v-toolbar color="primary " dark flat>
        <v-toolbar-title>{{title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
         <!--  <v-btn text>
            <v-icon>mdi-refresh</v-icon>
            Actualizar
          </v-btn>
          <v-btn text>
            <v-icon>mdi-delete</v-icon>
            quitar
          </v-btn> -->
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <v-card flat style="height: calc(100vh - 113px); overflow: auto">
            <v-card-text>
              <json-viewer
                :value="item.content"
                :expand-depth="5"
                :copyable="{
                  copyText: 'Copiar',
                  copiedText: 'Copiando',
                  timeout: 500,
                }"
              ></json-viewer>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<style scoped>
.jv-container .jv-code {
  overflow: hidden;
  padding: 2px 3px;
}
</style>
<script>
export default {
  props: ["title","openmodal", "json"],
  data() {
    return {
      jsonData: {
        message: "Cargando...",
      },
      tab: null,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },
  methods: {
    closeModal() {
      this.tab = null;
      // this.$emit("closeEvent", false);
    },
  },
  computed: {
    dialog: {
      get: function () {
        return this.openmodal;
      },
      set: function (value) {
         this.tab = null;
        this.$emit("closeEvent", value);
      },
    },
    items: {
      get: function () {
        
        return this.json;
      },
      set: function () {
        console.log("set items")
        //  this.$emit("closeEvent", value);
      },
    },
  },
};
</script>